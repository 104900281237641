/// <reference path="o365.pwa.declaration.sw.utilities.JsonDecoderStream.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as JsonDecoderStreamModule from 'o365.pwa.declaration.sw.utilities.JsonDecoderStream.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const JTOKEN_START_OBJECT = '{';
    const JTOKEN_END_OBJECT = '}';
    const JTOKEN_START_ARRAY = '[';
    const JTOKEN_END_ARRAY = ']';
    const JTOKEN_STRING_CONTAINER = '"';
    const JTOKEN_STRING_ESCAPE_CHARACTER = '\\'; 

    class JsonDecoderStream implements JsonDecoderStreamModule.JsonDecoderStream {
        private decoder: TextDecoder;
        private partialChunk: Uint8Array | null;
        private partialItem: string;
        private level: number;
        private insideTopLevelArray: boolean;
        private arrayIndex?: number;
        private stringOpen: boolean;
        private chunks: Array<string>;
        private openTypes: Array<JsonDecoderStreamModule.T_JTOKEN_START_OBJECT | JsonDecoderStreamModule.T_JTOKEN_START_ARRAY>;
        private topLevelWrapperType?: JsonDecoderStreamModule.T_JTOKEN_START_OBJECT | JsonDecoderStreamModule.T_JTOKEN_START_ARRAY;

        private options: { debug: boolean; };

        private get currentOpenType(): JsonDecoderStreamModule.T_JTOKEN_START_OBJECT | JsonDecoderStreamModule.T_JTOKEN_START_ARRAY | undefined {
            return this.openTypes.at(-1);
        }

        constructor(options: {
            debug: boolean;
        } = {
            debug: false
        }) {
            this.decoder = new TextDecoder();
            this.partialChunk = null;
            this.partialItem = '';
            this.level = 0;
            this.insideTopLevelArray = false;
            this.stringOpen = false;
            this.chunks = new Array();
            this.openTypes = new Array();
            this.options = options;
        }

        public decodeChunk(value: Uint8Array, decodedItemCallback: (item: any, arrayIndex?: number) => void): boolean {
            let chunk: string;

            try {
                const chunkRaw = this.partialChunk === null ? value : this.combineUint8Arrays(this.partialChunk, value);

                chunk = this.decoder.decode(chunkRaw);

                if (this.options.debug) {
                    this.chunks.push(chunk);
                }

                this.partialChunk = null;
            } catch (reason) {
                this.partialChunk = this.combineUint8Arrays(this.partialChunk ?? new Uint8Array(), value);
                
                return true;
            }

            let itemStart: number | null = this.partialItem.length > 0 ? 0 : null;

            for (var i = 0; i < chunk.length; i++) {
                if (this.stringOpen === false && chunk[i] === JTOKEN_STRING_CONTAINER) {
                    this.stringOpen = true;
                } else if (this.stringOpen === true && chunk[i] === JTOKEN_STRING_CONTAINER && this.isStringContainerEscaped(this.partialItem + chunk, this.partialItem.length + i, 0) === false) {
                    this.stringOpen = false;
                } else if (this.stringOpen === false) {
                    switch (chunk[i]) {
                        case JTOKEN_START_ARRAY:
                            if (this.level === 0 && this.topLevelWrapperType === JTOKEN_START_OBJECT) {
                                throw Error('Invalid JSON');
                            }

                            if (this.level === 0) {
                                this.topLevelWrapperType = JTOKEN_START_ARRAY;
                                this.insideTopLevelArray = true;

                                if (this.arrayIndex === undefined) {
                                    this.arrayIndex = 0;
                                } else {
                                    this.arrayIndex++;
                                }
                            }
                            
                            this.level++;
                            this.openTypes.push(JTOKEN_START_ARRAY);

                            break;
                        case JTOKEN_END_ARRAY:
                            if (this.currentOpenType !== JTOKEN_START_ARRAY) {
                                throw Error('Invalid JSON');
                            }

                            this.level--;
                            this.openTypes.pop();

                            if (this.level === 0) {
                                this.insideTopLevelArray = false;
                            }

                            break;
                        case JTOKEN_START_OBJECT:
                            if (this.level === 0 && this.topLevelWrapperType === JTOKEN_START_ARRAY) {
                                throw Error('Invalid JSON');
                            }

                            if (this.level === 0) {
                                this.topLevelWrapperType = JTOKEN_START_OBJECT;
                            }

                            if (
                                (this.level === 0 && !this.insideTopLevelArray) || 
                                (this.level === 1 && this.insideTopLevelArray)
                            ) {
                                itemStart = i;
                            }

                            this.level++;
                            this.openTypes.push(JTOKEN_START_OBJECT);

                            break;
                        case JTOKEN_END_OBJECT:
                            if (this.currentOpenType !== JTOKEN_START_OBJECT) {
                                throw Error('Invalid JSON');
                            }

                            this.level--;
                            this.openTypes.pop();
                            
                            if (
                                (this.level === 0 && !this.insideTopLevelArray) || 
                                (this.level === 1 && this.insideTopLevelArray)
                            ) {
                                let item = chunk.substring(itemStart!, i + 1);

                                if (this.partialItem) {
                                    item = this.partialItem + item;

                                    this.partialItem = '';
                                }

                                try {
                                    decodedItemCallback(JSON.parse(item), this.insideTopLevelArray ? this.arrayIndex : undefined);
                                } catch(error) {
                                    self.o365.logger.error(error, item, chunk);
                                }

                                itemStart = null;
                            }

                            break;
                    }
                }
            }

            if (itemStart !== null) {
                this.partialItem += chunk.substring(itemStart);
            }

            return false;
        }

        private combineUint8Arrays(array1: Uint8Array, array2: Uint8Array): Uint8Array {
            const combinedChunks = new Uint8Array(array1.length + array2.length);

            combinedChunks.set(array1, 0);
            combinedChunks.set(array2, array1.length);

            return combinedChunks;
        }

        private isStringContainerEscaped(text: string, index: number, escapeCount: number): boolean {
            if (index - 1 >= 0 && text[index - 1] === JTOKEN_STRING_ESCAPE_CHARACTER) {
                return this.isStringContainerEscaped(text, index - 1, escapeCount + 1);
            }

            if (escapeCount === 0) {
                return false;
            }

            return escapeCount % 2 === 1;
        }
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.utilities.JsonDecoderStream.d.ts')>({ JsonDecoderStream })
})();
